<template>
  <div class="page">
    <Breadcrumbs/>
    <div class="page-container block-container">
      <div class="column left-block large-block">
        <h1>Extended Validation (EV) SSL-certificaat</h1>
        <Price :item="data.staticData.prices.SSLCertificatesPrices[3]"/>
        <h2>EV certificaat</h2>
        <div>
          <p>
            <img
              class="framed-image"
              style="float: right; margin-left: 20px;"
              src="../../assets/images/sslcertificaat.png"
              height="300"
              width="265"
              title="ssl certificaat kopen, ssl verbinding, wat is ssl, ssl aanvragen"
              alt="ssl certificaat kopen, ssl verbinding, wat is ssl, ssl aanvragen"
            >
            Het EV SSL certificaat is de ideale keuze voor webshops, banken en andere websites die graag de maximale betrouwbaarheid willen uitstralen. EV staat voor Extended Validation. Het zorgt voor de bekende groene adresbalk bij de gebruiker, om aan de geven dat de verbinding veilig is. Bovendien maakt het EV-certificaat het eenvoudig om te controleren of de identiteit van de website in orde is, om daar veilig gebruik van te maken.
          </p>
          <p>Het EV SSL certificaat is de meest veilige keuze voor websites en webshops. In het Nederlands spreken we van ‘uitgebreide validatie’. De controles voor uitgifte zijn streng, waardoor de kosten wat hoger liggen dan bij de andere type certificaten. Bovendien gaat daar gemiddeld genomen iets meer tijd overheen.</p>
          <p><i>Tip: het EV certificaat toont een groene adresbalk, waarin zowel de URL als de bedrijfsnaam worden vermeld. Op die manier draagt het bij aan maximale betrouwbaarheid voor de bezoeker.</i></p>
          <h3>EV SSL certificaat vergelijken</h3>
          <p>Het EV SSL certificaat is de meest uitgebreide vorm, bijvoorbeeld ten opzichte van het standaard SSL certificaat (<router-link to="/ssl-certificaten/standaard-ssl-certificaat/">https://hostingondemand.nl/ssl-certificaten/standaard-ssl-certificaat/</router-link>) en ook een wildcard SSL-certificaat (<router-link to="/ssl-certificaten/wildcard-ssl-certificaat/">https://hostingondemand.nl/ssl-certificaten/wildcard-ssl-certificaat/</router-link>). Met name webshops en andere websites die een uiterst betrouwbare indruk willen maken kunnen hier goed gebruik van maken.</p>
          <p>We leveren het EV certificaat standaard binnen 1 – 10 dagen. Hiervoor zijn we afhankelijk van de controle die er plaatsvindt op de identiteit. Het certificaat is vervolgens geschikt voor 1 domein en biedt een garantie tot €1.500.000. De adresbalk kleurt groen, waardoor bezoekers direct zien dat ze op een betrouwbare website terecht zijn gekomen en bijvoorbeeld een aankoop kunnen doen of gegevens kunnen vezenden.</p>
        </div>
        <h3>Voordelen van een SSL certificaat bij Hosting on Demand</h3>
        <p>Bij Hosting on Demand profiteert u met een SSL certificaat van:</p>
        <ul>
          <li>Een 100% beveiligde verbinding</li>
          <li>Snelle levering van ieder SSL certificaat</li>
          <li>Betere vindbaarheid in Google</li>
        </ul>
        <p>Het EV SSL certificaat kopen? U kunt daarmee uw eigen website beveiligen, zodat bezoekers daar zonder problemen gebruik van kunnen maken. De SSL verbinding is 100% veilig en draagt op die manier bij aan de betrouwbare uitstraling van uw organisatie. Het is om die reden de beste keuze voor webshops, banken en andere websites die veel waarde hechten aan de betrouwbaarheid.</p>
        <RandomQuote/>
      </div>
      <SSLCertificatesRightSideBlocks/>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue';

import Breadcrumbs from '@/components/Breadcrumbs.vue';
import Price from '@/components/Price.vue';
import SSLCertificatesRightSideBlocks from '@/components/SSLCertificatesRightSideBlocks.vue';
import RandomQuote from '@/components/RandomQuote.vue';

export default {
  name: 'EVSSLCertificate',
  components: {
    Breadcrumbs,
    Price,
    SSLCertificatesRightSideBlocks,
    RandomQuote,
  },
  setup() {
    const data = inject('data');

    return {
      // static data
      data,
    };
  },
}
</script>