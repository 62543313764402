<template>
  <div class="column right-block small-block">
    <div class="green-block">
      <h3>Daarom bij ons!</h3>
      <p>Waarom SSL-certificaten van Hosting on Demand?</p>
      <ul>
        <li>Advies over alle type certificaten</li>
        <li>Snelle levering</li>
        <li>Geautomatiseerde installatie</li>
        <li>Technische ondersteuning</li>
        <li>Competitieve prijzen</li>
      </ul>
    </div>
    <div class="green-block">
      <h3>Veel gestelde vragen over domeinnamen</h3>
      <ExpansionPanels
        :panels="questions.map(q => q.title)"
      >
        <template
          v-for="(question, index) in questions"
          :key="index"
          v-slot:[questionSlotName(index)]
        >
          <div v-html="question.content"></div>
        </template>
      </ExpansionPanels>
    </div>
    <div class="green-block">
      <h3>Meer informatie over domeinnamen</h3>
      <ul>
        <li><router-link to="/ssl-certificaten/overige-ssl-certificaten/">Overige type SSL-certificaten</router-link></li>
        <li><router-link to="/ssl-certificaten/ev-certificaat/">Extended Validation (EV) SSL-certificaat</router-link></li>
        <li><router-link to="/ssl-certificaten/multi-domein-ssl-certificaat/">Multi-domein SSL-certificaat</router-link></li>
        <li><router-link to="/ssl-certificaten/wildcard-ssl-certificaat/">Wildcard SSL-certificaat</router-link></li>
        <li><router-link to="/ssl-certificaten/standaard-ssl-certificaat/">Standaard SSL-certificaat</router-link></li>
      </ul>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue';

import ExpansionPanels from '@/components/ExpansionPanels.vue';

export default {
  name: 'SSLCertificatesRightSideBlocks',
  components: {
    ExpansionPanels,
  },
  setup() {
    const data = inject('data');
    const questions = data.staticData.questions.filter(q => q.tags.split(' ').includes('ssl-certificates'));

    function questionSlotName(index) {
      return `content-text-${index}`;
    }

    return {
      // static data
      questions,

      // methods
      questionSlotName,
    };
  },
}
</script>